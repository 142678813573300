<template>
  <div>
    <aw-input-version2
      v-model.lazy="dateValue"
      :has-error="hasError"
      :is-focused="isFocused"
      :has-success="hasSuccess"
      :has-label="Boolean(hasLabel && isMounted && screenRange['mobile-max'])"
      :widget-attrs="widgetAttrs"
      :widget-icon-attrs="widgetIconAttrs"
      :is-invalid="isInvalid"
      :is-required="isRequired"
      :row-unique-id="extendedRowUniqueId.join(' ')"
      :floating-label-text="floatingLabelText"
      type="text"
      :data-input="true"
      @blur="onBlur"
      @aw-input-blur="AwInputBlur"
      @keydown.enter="onBlur"
    />
    <label :id="extendedRowUniqueId[0]" class="awSrOnly" v-text="$awt('aw.common.form.valid_date_format')" />
  </div>
</template>

<script>
  import { mapState } from 'pinia';
  import { nextTick, withModifiers } from 'vue';
  import Flatpickr from './flatpickr_fork';

  import AwInputVersion2 from '~~/common/components/Common/AwInputVersion2.vue';
  import { Hungarian } from '~~/common/components/Common/DatePicker/flatpickr_fork/l10n/hu.js';
  import English from '~~/common/components/Common/DatePicker/flatpickr_fork/l10n/default.js';
  import { useUserInterfaceStore } from '~~/common/stores/userInterface';
  import { createValidator } from '~~/common/utils/form.js';

  const camelToKebab = (string) => {
    return string.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase();
  };

  const arrayify = (obj) => {
    return Array.isArray(obj) ? obj : [obj];
  };

  const nullify = (val) => {
    return (val && val.length) ? val : null;
  };

  const cloneObject = (obj) => {
    return Object.assign({}, obj);
  };

  const includedEvents = [
    'onChange',
    'onClose',
    'onDestroy',
    'onMonthChange',
    'onOpen',
    'onYearChange',
  ];

  // Let's not emit these events by default
  const excludedEvents = [
    'onValueUpdate',
    'onDayCreate',
    'onParseConfig',
    'onReady',
    'onPreCalendarPosition',
    'onKeyDown',
  ];

  // Keep a copy of all events for later use
  const allEvents = includedEvents.concat(excludedEvents);

  // Passing these properties in `set()` method will cause flatpickr to trigger some callbacks
  const configCallbacks = ['locale', 'showMonths'];

  export default {
    name: 'AwDatePicker',
    components: {
      AwInputVersion2,
    },
    props: {
      rowUniqueId: {
        type: String,
        required: true,
      },
      hasError: {
        type: Boolean,
        required: true,
      },
      isFocused: {
        type: Boolean,
        default: false,
      },
      hasLabel: {
        type: Boolean,
        required: true,
        default: false,
      },
      hasSuccess: {
        type: Boolean,
        required: true,
      },
      isInvalid: {
        type: Boolean,
        required: true,
      },
      isRequired: {
        type: Boolean,
        required: true,
      },
      widgetAttrs: {
        type: Object,
        default: () => ({}),
      },
      widgetIconAttrs: {
        type: Object,
        default: () => ({}),
      },
      modelValue: {
        default: null,
        required: true,
        validator (val) {
          return (
            val === null ||
            val instanceof Date ||
            typeof val === 'string' ||
            val instanceof String ||
            Array.isArray(val) ||
            typeof val === 'number'
          );
        },
      },
      // https://flatpickr.js.org/options/
      config: {
        type: Object,
        default: () => ({
          wrap: false,
          defaultDate: null,
        }),
      },
      events: {
        type: Array,
        default: () => includedEvents,
      },
      floatingLabelText: {
        type: String,
        default: null,
      },
    },
    emits: [
      'update:modelValue',
      ...allEvents.map(m => camelToKebab(m)),
    ],
    data () {
      return {
        /**
         * The flatpickr instance
         */
        isMounted: false,
        fp: null,
        defaultConfig: {
          wrap: true,
          allowInput: true,
          dateFormat: 'Y-m-d',
          altFormat: 'Y.m.d.',
          altInput: true,
          locale: this.$i18n.locale === 'hu' ? Hungarian : English,
          nextArrow: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M6 13L9.58579 9.41421C10.3668 8.63316 10.3668 7.36683 9.58579 6.58579L6 3" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          prevArrow: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 3L6.41421 6.58579C5.63316 7.36684 5.63317 8.63317 6.41421 9.41421L10 13" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          upArrow: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M13 10L9.41421 6.41422C8.63317 5.63317 7.36684 5.63317 6.58579 6.41421L3 10" stroke="currentColor" stroke-linecap="round"/></svg>',
          downArrow: '<svg width="16" height="16" viewBox="0 0 16 16" fill="none"  xmlns="http://www.w3.org/2000/svg"><path d="M3 6L6.58579 9.58578C7.36683 10.3668 8.63316 10.3668 9.41421 9.58579L13 6" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/></svg>',
          plugins: [],
        },
      };
    },
    computed: {
      ...mapState(useUserInterfaceStore, {
        screenRange: state => state.mediaQueries,
      }),
      extendedRowUniqueId () {
        return [`${this.rowUniqueId}_description`, this.rowUniqueId];
      },
      dateValue: {
        get () {
          return this.formatVisibleValue(this.modelValue);
        },
        set () {
          // we don't need set after all character change
          // this.$emit('update:modelValue', newValue);
        },
      },
    },
    mounted () {
      this.isMounted = true;
      if (!this.widgetAttrs.disabled) {
        nextTick(() => {
          nextTick(() => {
            // Return early if flatpickr is already loaded
            /* istanbul ignore if */
            if (this.fp) {
              return;
            }

            // Don't mutate original object on parent component
            const safeConfig = cloneObject({ ...this.config, ...this.defaultConfig });

            this.events.forEach((hook) => {
              // Respect global callbacks registered via setDefault() method
              const globalCallbacks = Flatpickr.defaultConfig[hook] || [];

              // Inject our own method along with user callback
              const localCallback = (...args) => {
                this.$emit(camelToKebab(hook), ...args);
                if (hook === 'onClose') {
                  this.onClose(...args);
                }
              };

              // Overwrite with merged array
              safeConfig[hook] = arrayify(safeConfig[hook] || []).concat(globalCallbacks, localCallback);
            });

            // Set initial date without emitting any event
            safeConfig.defaultDate = this.modelValue || safeConfig.defaultDate;

            // Init flatpickr
            this.fp = new Flatpickr(this.getElem(), safeConfig);

            // Immediate watch will fail before fp is set,
            // so need to start watching after mount
            this.$watch('widgetAttrs.disabled', this.watchDisabled, { immediate: true });
            this.$watch('config', this.watchConfig, { immediate: true, deep: true });
            this.$watch('modelValue', this.watchModelValue, { immediate: true });
          });
        });
      }
    },
    /**
     * Free up memory
     */
    beforeUnmount () {
      /* istanbul ignore else */
      if (this.fp) {
        this.fp.destroy();
        this.fp = null;
      }
    },
    methods: {
      onBlur (data) {
        this.$emit('update:modelValue', data?.target?.value);
      },
      AwInputBlur (data) {
        this.$emit('update:modelValue', data?.target?.value);
      },

      /**
       * Get the HTML node where flatpickr to be attached
       * Bind on parent element if wrap is true
       */
      getElem () {
        return this.config.wrap ? this.$el.parentNode : this.$el;
      },

      /**
       * Watch for value changed by date-picker itself and notify parent component
       *
       * @param event
       */
      onInput (event) {
        const input = event.target;
        // Let's wait for DOM to be updated
        nextTick(() => {
          this.$emit('update:modelValue', nullify(input.value));
        });
      },

      /**
       * @return HTMLElement
       */
      fpInput () {
        return this.fp.altInput || this.fp.input;
      },

      /**
       * Flatpickr does not emit input event in some cases
       */
      onClose (selectedDates, dateStr) {
        this.$emit('update:modelValue', nullify(dateStr));
      },

      /**
       * Watch for changes from parent component and update DOM
       *
       * @param newValue
       */
      watchModelValue (newValue) {
        // Prevent updates if v-model value is same as input's current value
        const val = nullify(this.fp?.input?.value);
        if (val === newValue || val === null) {
          return;
        }
        // Notify flatpickr instance that there is a change in modelValue
        this.fp.setDate(newValue, true);
      },

      /**
       * Watch for the disabled property and sets the value to the real input.
       *
       * @param newState
       */
      watchDisabled (newState) {
        if (newState) {
          this.fpInput().setAttribute('disabled', newState);
        } else {
          this.fpInput().removeAttribute('disabled');
        }
      },

      /**
       * Watch for any config changes and redraw date-picker
       *
       * @param newConfig Object
       */
      watchConfig (newConfig) {
        const safeConfig = cloneObject({ ...newConfig, ...this.defaultConfig });
        // Workaround: Don't pass hooks to configs again otherwise
        // previously registered hooks will stop working
        // Notice: we are looping through all events
        // This also means that new callbacks can not passed once component has been initialized
        allEvents.forEach((hook) => {
          // eslint-disable-next-line @typescript-eslint/no-dynamic-delete
          delete safeConfig[hook];
        });
        this.fp.set(safeConfig);

        // Workaround: Allow to change locale dynamically
        configCallbacks.forEach((name) => {
          if (typeof safeConfig[name] !== 'undefined') {
            this.fp.set(name, safeConfig[name]);
          }
        });
      },
      formatVisibleValue (val) {
        if (val) {
          if (!isNaN(Date.parse(val))) {
            return this.$date(val, {
              year: 'numeric',
              month: '2-digit',
              day: '2-digit',
            }, {
              removeSpaces: true,
            });
          } else {
            return val;
          }
        } else {
          return '';
        }
      },
    },
  };

  export const awDatePickerProps = createValidator(
    function (
      { basicValidatorProps },
      { model } = {},
    ) {
      return {
        error: basicValidatorProps.error,
        field: {
          ...basicValidatorProps.field,
          modelValue: model.value,
          'onUpdate:modelValue': model === null ? null : withModifiers((newVal) => {
            model.value = newVal;
          }, ['trim']),
          widgetAttrs: {},
          widgetIconAttrs: {
            size: 16,
            name: 'calendar-version2-16',
            position: 'after',
            'data-toggle': true,
          },
        },
      };
    },
  );

</script>
